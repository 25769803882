<template>
    <main class="h-100" v-if="selectedViewpoint">
      <div class="container mt-4">
        <div class="d-flex">
          <div class="h4" v-if="!isEditingName" @click="isEditingName = true">{{ selectedViewpoint.name || selectedViewpoint.id }}</div>  
          <div class="input-group input-group-lg" v-else>
            <!-- <span class="input-group-text" id="inputGroup-sizing-lg">Large</span> -->
            <input
              v-model="selectedViewpoint.name"
              type="text"
              class="form-control"
              style="max-width: 400px"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              placeholder="Video Name"
              @keydown.enter="isEditingName = false;upsertViewpoint(selectedViewpoint.id)"
              @blur="isEditingName = false;upsertViewpoint(selectedViewpoint.id)"
            />
          </div>

          <div class="btn btn-warning ms-2 btn-sm" style="height: 32px" @click="addTag">Add Tag</div>
          <div class="btn btn-danger ms-2 btn-sm" style="height:32px" @click="todo">Delete Viewpoint</div>
          <router-link :to="`/Viewpoint/${selectedViewpoint.id}/annotate`" v-slot="{ navigate }">
            <button class="btn btn-success ms-2 btn-sm" style="height:32px;" @click="navigate">Start Annotating</button>
          </router-link>
        </div>

        <div>
          <div>Viewpoint Type: Unknown</div>
          <div>Viewpoint Length: {{ selectedViewpoint.duration }}</div>
          <div>Frames Extracted? {{ selectedViewpoint.framesGenerated ? 'Yes' : 'No' }}</div>
          <div>Total Frames Extracted: {{ selectedViewpoint.totalFrames }}</div>

          <div class="d-flex">Tags: 
            <div
              v-for="tag in selectedViewpoint.tags"
              :key="tag"
              class="btn btn-xs btn-secondary my-auto mx-1"
              style="height: 22px;"
              @click="
                selectedViewpoint.tags = selectedViewpoint.tags.filter(x => x != tag);
                upsertViewpoint(selectedViewpoint.id)
              ">{{tag}}</div>
          </div>
          <div>
            <div>Viewpoint SAS url: <input class="form-control" v-model="selectedViewpoint.url" disabled/></div>
          </div>
        </div>
        <br>
        <!-- <h3>Coming soon</h3>
        <ul>
          <li>Annotating viewpoint</li>
          <li>Uploading existing viewpoint tags</li>
          <li>Viewpoint Data Type (rgb, depth, IR, AER)</li>
          <li>Reviewing viewpoint</li>
        </ul> -->
        <div class="d-flex">
          <div v-if="selectedViewpoint.url">
            <video controls muted width="500px" :src="selectedViewpoint.url"></video>
          </div>
          <div class="ms-3">
            <div v-if="selectedViewpoint.eventsCsv">
              <div style="width: 100%; overflow-x: auto;">
                <div :style="{ width: (2000) + 'px', height: '300px'}">
                  <line-chart :height="300" :width="2000"/>
                </div>
              </div>
            </div>
            <div v-else class="ms-3">No events data</div>
          </div>
        </div>
      </div>
    </main>
    <main class="h-100 w-100" v-else>
      <div v-if="errorLoading" class="h-100 w-100 d-flex justify-content-center">
        <div class="text-center my-auto"> Unable to load viewpoint: {{ errorLoading }}</div>
      </div>
      <div v-else class="container">
        Loading
      </div>
    </main>
</template>
<script>

import LineChart from '../components/LineChart.vue';
import { mapActions, mapGetters } from 'vuex';
import Axios from "axios"
export default {
  mixins: {  },
  components: {
    LineChart
  },
    data() {
        return {
          errorLoading: null,
          isEditingName: false,
          events: {}, // name => count
        }
    },
  async mounted() {
    console.log("Viewpoint mounted");
    try { 
      console.log(`Requesting viewpoint ID: ${this.$route.params.id}`)
      await this.$store.dispatch('getViewpoint', this.$route.params.id);
      await this.$store.dispatch('selectViewpoint', this.$route.params.id);
      console.log(`Got viewpoint ID: ${this.$route.params.id}`)

      if (this.selectedViewpoint.eventsCsv){
        try {
          const { data: events } = await Axios.get(this.selectedViewpoint.eventsCsv)
          this.eventsData = events;
          console.log("got events")
        } catch (e) {
          console.error('unable to load events')
          console.error(e.stack)
        }
      } else {
        console.log("No event data")
      }
    } catch (e) {
      this.errorLoading = e.message;
    }
  },
  async destroyed() {
    console.log("Viewpoint destroyed");
  },
  computed: {
      ...mapGetters(['selectedViewpoint']),
      // viewpoint: () => this.selectedViewpoint,
  },
  watch: {
  },
  methods: {
    ...mapActions(['upsertViewpoint']),
    async addTag() {
      try {
        let { value, dismiss } = await this.Swal.fire({
          title: "New tag",
          input: "text"
        });

        this.Swal.showLoading();

        this.selectedViewpoint.tags = this.selectedViewpoint.tags || [];
        if (this.selectedViewpoint.tags.find(x => x == value)) return;
        this.selectedViewpoint.tags.push(value);
        await this.upsertViewpoint(this.selectedViewpoint.id);

        this.Swal.close()
      } catch (e) {
        this.Swal.fire(`Error`, `Unable to add tag: ${e.message}`, "error");
      }
      
    },
    calculateEventsData() {
      const events = this.eventsData.split('\n');
      if (!events) return {};

        let eventsDatasets = {};
        let eventCountsAfterFilteringTemp = {};
        let filteringToggles = {};
        this.niceData = [];
        let times = []

        let columns = events.splice(0, 1)[0].split(',');
        // frameId, time
        let headerInfo = columns.splice(0, 2);
        let classNames = columns;

        

        for (const row of events) {
            let timeIndex = metric.timeindex;
            if (!timeIndex && metric.frameId) timeIndex = /-out-(\d+)\.jpg/.exec(metric.frameId)[1]
                
            let row = { timeIndex, threshold: this.threshold };
            times.push(timeIndex);

            for (const event of metric.event_predictions) {
                eventsDatasets[event.tagName] = eventsDatasets[event.tagName] || [];
                eventCountsAfterFilteringTemp[event.tagName] = eventCountsAfterFilteringTemp[event.tagName] || 0;
                filteringToggles[event.tagName] = filteringToggles[event.tagName] || false;
            
                eventsDatasets[event.tagName].push({
                    x: timeIndex,
                    y: event.probability,
                });

                if (event.probability > this.threshold) {
                    // We have gone above threshold 
                    if (!filteringToggles[event.tagName]) {
                        filteringToggles[event.tagName] = true
                        eventCountsAfterFilteringTemp[event.tagName]++;
                    }
                } else {
                    // We have dropped below threshold
                    if (filteringToggles[event.tagName]) {
                        filteringToggles[event.tagName] = false;
                    }
                }

                row[event.tagName] = event.probability
                row[event.tagName + "_count"] = eventCountsAfterFilteringTemp[event.tagName]
                row[event.tagName + "_detected"] = event.probability > this.threshold
            }

            this.niceData.push(row);
        }

        this.timeIndexes = times;
        this.eventCountsAfterFiltering = eventCountsAfterFilteringTemp;

        let datasets = Object.keys(eventsDatasets).map(eventName => ({
            label: eventName,
            data: eventsDatasets[eventName],
            ...this.colourMappings[eventName]
        }));
        
        this.chartDatasets = datasets;
    }
  }
}
</script>

<style>

</style>